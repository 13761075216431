import React, { useState, useEffect, useContext } from "react";
import "../Product_Cart.css";
import "../Shop_Now/Shop_Now.css";
import {
  Breadcrumb,
  Button,
  Form,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import CoupanModal from "../../Common_modal/coupon_modal/couponModal";
import CouponModal from "../../Common_modal/CouponModal/CouponModal";

import { Context } from "../../../utils/context";
import ProgressBar from "react-bootstrap/ProgressBar";

import {
  CartQuantity,
  deleteCart,
  getCart,
} from "../../../utils/apis/addToCart/addToCart";

import { getCouponCode } from "../../../utils/apis/orderProcess/orderProcess";
import { Errors } from "../../../utils/errors";
import { useLocation } from "react-use";
import { getAppSetup } from "../../../utils/apis/common/Common";
import axios from "axios";
import {
  UserGet,
  UserGetInvestCount,
} from "../../../utils/apis/myAccount/myAccount";

const Cart = ({
  type,
  amount,
  setAmount,
  discount,
  setDiscount,
  coupon,
  setCoupon,
  setTotalTax,
  tax,
  setDeliveryInstruction,
}) => {
  const navigate = useNavigate();
  const {
    IMG_URL,
    signin,
    triggerCartUpdate,
    setopen_delivery,
    open_delivery,
    setquick_delivery,
    quick_delivery,
    appSetup,
    setAppSetup,
    setSellerPincode,
    sellerPincode,
    deliveryPincode,
    outOfStock,
    userdata,
    setUserData,
  } = useContext(Context);

  const getVariant = (amount) => {
    if (amount <= 3000) {
      return "success"; // Green
    } else if (amount <= 6000) {
      return "warning"; // Yellow
    } else {
      return "danger"; // Red
    }
  };

  const [investCount, setInvestCount] = useState("");

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
    }
  };

  const getUserinvestCount = async () => {
    const res = await UserGetInvestCount();
    if (res?.success) {
      setInvestCount(res.data);
    }
  };

  useEffect(() => {
    getUser();
    getUserinvestCount();
  }, []);

  const getDistance = async (sellerPin, deliveryPin) => {
    const apiKey = "AIzaSyAGSck_G-HPj42U4xyH4jOnmjPm1HgQ9Fk";
    // const url = `https://maps.googleapis.com/maps/api/directions/json?origin=${sellerPin}&waypoints=optimize:true|&destination=${deliveryPin}&key=${apiKey}&mode=driving&language=en&region=undefined%22`;
    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=${sellerPin}&destinations=${deliveryPin}&key=${apiKey}`;

    try {
      const response = await axios.get(url);

      const data = response.data;

      if (data.status === "OK") {
        const distance = data.rows[0].elements[0].distance.text;

        return distance;
      } else {
        console.error("Error in API response:", data.status);
        return null;
      }
    } catch (error) {
      console.error("Error fetching distance:", error);
      return null;
    }
  };

  //   try {
  //     const result = await axios.get(
  //       `https://maps.googleapis.com/maps/api/directions/json?origin=${StartLat},${StartLong}&waypoints=optimize:true|&destination=${EndLat},${EndLong}&key=${GOOGLE_MAPS_API_KEY}&mode=driving&language=en&region=undefined%22`,
  //     );
  //     console.info(
  //       JSON.stringify({StartLat, StartLong, EndLat, EndLong, result}),
  //     );
  //     return result;
  //   } catch (error) {
  //     console.warn({error});
  //     return error?.response;
  //   }
  // }

  useEffect(() => {
    const fetchDistance = async () => {
      const distance = await getDistance(sellerPincode, deliveryPincode);
    };

    fetchDistance();
  }, [sellerPincode, deliveryPincode]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const appSetupGet = async () => {
    const res = await getAppSetup();
    if (res?.success) {
      setAppSetup(res?.data);
    }
  };

  useEffect(() => {
    appSetupGet();
  }, []);

  const location = useLocation();
  const showButton = location.pathname !== "/product-cart-details";
  var [grandTotal, setGrandTotal] = useState(0);
  var [message, setMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [couponStatus, setCouponStatus] = useState(false);

  console.log(couponStatus, "couponStatus");

  const [copyCode, setCopyCode] = useState("");
  const [cardError, setCardError] = useState();
  let totalPrice = 0;

  let totalTax = 0;

  let extraDiscount = 0;

  let discountRate = 0;

  let discountAmount = 0;

  let totalWithTax = totalPrice + Number(totalTax.toFixed(2));
  let discountedTotal = totalWithTax * 0.85;

  const handleDeleteRecord = async (id) => {
    const response = await deleteCart(id);
    if (response?.success) {
      setModalShow(true);

      setTimeout(() => {
        cartGet();
        setModalShow(false);
      }, 2000);
    } else {
      console.error("Failed to delete the record");
    }
  };

  const onSubmit = async (data) => {
    setError("code", "");

    const res = await getCouponCode(data?.code, amount);
    if (res?.success) {
      if (res?.couponCode) {
        setModalShow(true);
        setCoupon(res?.couponCode?.id);
        setCouponStatus(true);

        if (res?.couponCode?.type === "percentage") {
          setDiscount(
            (Number(totalPrice) * Number(res?.couponCode?.discount)) / 100
          );
          setMessage(`${res?.couponCode?.discount} % Discount Applied`);
          setCardError("");
        } else {
          setDiscount(res?.couponCode?.total_amount);
          setMessage(`Coupon Applied of Rs. ${res?.couponCode?.total_amount}`);
        }
        setTimeout(() => {
          setModalShow(false);
          setMessage("");
          setCardError("");
        }, 3000);
      } else {
        setDiscount(0);
        setCoupon("");
        setCouponStatus(false);
        setError("code", { message: "Coupon Invalid" });
        setCardError("");
      }
    } else {
      setDiscount(0);
      setCoupon("");
      setCouponStatus(false);
      setError("code", {
        message: res?.message || "Invalid Coupon",
      });
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [cart, setCart] = useState([]);

  let productmembership = cart?.map((value) => value?.product?.membership_type);

  useEffect(() => {
    if (cart.length > 0) {
      setSellerPincode(cart[0].product?.user?.store_detail?.pincode?.name);
    }
  }, [cart]);

  const getCartQuantity = async (id, type) => {
    setDiscount(0);
    setCoupon();
    setCouponStatus(false);
    setError("code", "");

    await CartQuantity(id, type);
    if (Cookies.get("boomer_bucks_cart")) {
      if (JSON.parse(Cookies.get("boomer_bucks_cart")).length === 0) {
        navigate("/shop-now");
      }
      setCart(JSON.parse(Cookies.get("boomer_bucks_cart")));
      withoutCart();
    }
    if (signin) {
      await cartGet();
    }
    triggerCartUpdate();
  };

  const cartGet = async () => {
    const res = await getCart();
    if (res?.success) {
      if (res?.data.length === 0) {
        navigate("/shop-now");
      }
      setCart(res?.data);
    }
  };

  const withoutCart = async () => {
    if (Cookies.get("boomer_bucks_cart")) {
      if (JSON.parse(Cookies.get("boomer_bucks_cart")).length === 0) {
        navigate("/shop-now");
      }
      await setCart(JSON.parse(Cookies.get("boomer_bucks_cart")));
    }
  };

  useEffect(() => {
    if (signin) {
      cartGet();
    } else {
      withoutCart();
    }
  }, [Cookies.get("boomer_bucks_cart"), signin]);

  useEffect(() => {
    getCartQuantity();
  }, []);

  const handleNavigate = async (product_id) => {
    localStorage.setItem("product_id", product_id);
    navigate("/vendor-page");
  };

  const handleOpenDeliveryChange = (e) => {
    setopen_delivery(e.target.checked);
  };

  const handleQuickDeliveryChange = () => {
    setquick_delivery(1);
  };

  const handleChange = (e) => {
    const instruction = e.target.value;

    setDeliveryInstruction(instruction); // Call the parent function to set the instruction
  };

  return (
    <>
      {cart.length > 0 ? (
        <div className="row">
          <div className="col-xxl-9 col-xl-9 col-lg-8">
            <div className="product_table">
              {signin
                ? cart?.map((value, index) => {
                    totalPrice +=
                      (value?.product_variant?.s_price
                        ? value.product_variant.s_price
                        : value?.product_multiple_variant?.s_price) *
                      value?.quantity;

                    totalTax +=
                      (value?.product?.product_detail?.tax_percentage?.name *
                        (value?.product_variant?.s_price
                          ? value?.product_variant?.s_price
                          : value?.product_multiple_variant?.s_price *
                            value?.quantity)) /
                      100;
                    return (
                      <div className="row tabless mb-5">
                        <div className="col-xxl-3 col-xl-3  col-lg-3 col-md-3 col-sm-3 col-4">
                          <div
                            className="cabbageimg"
                            onClick={() => handleNavigate(value?.product_id)}
                          >
                            <img
                              src={IMG_URL + value?.product?.image1}
                              className="cartimg"
                            />
                          </div>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-8">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="prices">
                                <p className="cabbage ">
                                  {value?.product?.name}({" "}
                                  {value?.product_variant?.v_name
                                    ? value.product_variant.v_name
                                    : value?.product_multiple_variant
                                        ?.varient_sub_name}
                                  )
                                </p>
                              </div>
                            </div>

                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 ">
                              <div className="prices">
                                <p className="prce me-2">
                                  Price :{" "}
                                  <span>
                                    ₹
                                    {value?.product_variant?.s_price
                                      ? value?.product_variant?.s_price
                                      : value?.product_multiple_variant
                                          ?.s_price}
                                  </span>
                                </p>

                                <p className="prce">
                                  Tax :{" "}
                                  <span>
                                    ₹
                                    {(value?.product?.product_detail
                                      ?.tax_percentage?.name *
                                      (value?.product_variant?.s_price
                                        ? value?.product_variant?.s_price
                                        : value?.product_multiple_variant
                                            ?.s_price * value?.quantity)) /
                                      100}
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-0 "></div>

                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                              <div className="prices">
                                {/* <p className="cabbage cabbage2">{value?.product?.name}</p> */}
                                <p className="prce">
                                  Total Price:{" "}
                                  <span>
                                    ₹
                                    {value?.product_variant?.s_price
                                      ? (
                                          value?.product_variant?.s_price *
                                          value?.quantity
                                        ).toFixed(2)
                                      : (
                                          value?.product_multiple_variant
                                            ?.s_price * value?.quantity
                                        ).toFixed(2)}
                                    {/* {(
                                      value?.product_multiple_variant?.s_price?value?.product_multiple_variant?.s_price: *
                                        value?.quantity +
                                      (value?.product?.product_detail
                                        ?.tax_percentage?.name *
                                        (value?.product_multiple_variant
                                          ?.s_price *
                                          value?.quantity)) /
                                        100
                                    ).toFixed(2)} */}
                                  </span>
                                </p>
                                {location.pathname == "/product-cart" && (
                                  <div className="qnty">
                                    <p className="prce mb-0">Quantity:</p>
                                    <div className="counting">
                                      <button
                                        className="count-btn"
                                        type="button"
                                        onClick={async () => {
                                          await getCartQuantity(
                                            value?.id,
                                            "minus"
                                          );
                                          await cartGet();
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon="fa-solid fa-minus"
                                          className="minnusicns"
                                        />
                                      </button>
                                      <p className=" prce nombers">
                                        {value?.quantity}
                                      </p>
                                      <button
                                        className="count-btn"
                                        type="button"
                                        onClick={async () => {
                                          await getCartQuantity(
                                            value?.id,
                                            "add"
                                          );
                                          await cartGet();
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon="fa-solid fa-plus"
                                          className="plusicns"
                                        />
                                      </button>
                                    </div>
                                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5  trash-icon-holder">
                                      <div className="prices">
                                        <p className="cabbage cabbage2"></p>
                                        <p className="prce">
                                          <span></span>
                                        </p>
                                        <button
                                          className="count-btn trash-icon_btn"
                                          type="button"
                                          onClick={() =>
                                            handleDeleteRecord(value?.id)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="fa-solid fa-trash"
                                            className=" trash-icon"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : cart?.map((value, index) => {
                    totalPrice += value?.price * value?.quantity;

                    totalTax +=
                      (value?.tax_percent * (value?.price * value?.quantity)) /
                      100;
                    return (
                      <div className="row tabless mb-4">
                        <div className="col-xxl-2 col-xl-2  col-lg-3 col-md-3 col-sm-3 col-4">
                          <div
                            className="cabbageimg"
                            onClick={() => handleNavigate(value?.product_id)}
                          >
                            <img
                              src={IMG_URL + value?.image}
                              className="cartimg"
                            />
                          </div>
                        </div>
                        <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-9 col-sm-9 col-8">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="prices">
                                <p className="cabbage ">
                                  {value?.product?.name}
                                </p>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 ">
                              <div className="prices">
                                <p className="prce me-2">
                                  Price : <span>₹{value?.price}</span>
                                </p>

                                <p className="prce">
                                  Tax :{" "}
                                  <span>
                                    ₹
                                    {(value?.tax_percent *
                                      (value?.price * value?.quantity)) /
                                      100}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 "></div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                              <div className="prices">
                                {/* <p className="cabbage cabbage2">{value?.product?.name}</p> */}
                                <p className="prce">
                                  Total Price:{" "}
                                  <span>
                                    ₹
                                    {(
                                      value?.price * value?.quantity +
                                      (value?.tax_percent *
                                        (value?.price * value?.quantity)) /
                                        100
                                    ).toFixed(2)}
                                  </span>
                                </p>
                                <div className="qnty">
                                  <p className="prce mb-0">Quantity:</p>
                                  <div className="counting">
                                    <button
                                      className="count-btn"
                                      type="button"
                                      onClick={async () => {
                                        await getCartQuantity(
                                          value?.id,
                                          "minus"
                                        );
                                        await cartGet();
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-minus"
                                        className="minnusicns"
                                      />
                                    </button>
                                    <p className=" prce nombers">
                                      {value?.quantity}
                                    </p>
                                    <button
                                      className="count-btn"
                                      type="button"
                                      onClick={async () => {
                                        await getCartQuantity(value?.id, "add");
                                        await cartGet();
                                      }}
                                    >
                                      {/* <FontAwesomeIcon
                                        icon="fa-solid fa-plus"
                                        className="plusicns"
                                      /> */}
                                    </button>
                                  </div>
                                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5  trash-icon-holder">
                                    <div className="prices">
                                      <p className="cabbage cabbage2"></p>
                                      <p className="prce">
                                        <span></span>
                                      </p>
                                      {/* <button
                                        className="count-btn trash-icon_btn"
                                        type="button"
                                        onClick={() => setModalShow(true)}
                                      >
                                        <FontAwesomeIcon
                                          icon="fa-solid fa-trash"
                                          className=" trash-icon"
                                        />
                                      </button> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
            <Link to={"/"}>
              <div className="Continue_Shopping_text">
                <p>Continue Shopping</p>
              </div>
            </Link>

            <div className="mb-2">
              <strong>Membership Price Flow</strong>
            </div>

            <div classNames="mt-2" style={{ position: "relative" }}>
              <ProgressBar style={{ height: "50px" }}>
                {/* Segment 1: 0 to 3000 */}
                <ProgressBar
                  now={Math.min(investCount?.amount, 3000)}
                  max={10000} // Keep max as 10000 to ensure the total progress bar is consistent
                  label={
                    investCount?.amount <= 3000
                      ? `${investCount?.amount} ₹`
                      : null
                  }
                  variant="danger"
                  key={1}
                />

                {/* Segment 2: 3000 to 6000 */}
                {investCount?.amount > 3000 && (
                  <ProgressBar
                    now={Math.min(investCount?.amount - 3000, 3000)}
                    max={10000}
                    label={
                      investCount?.amount <= 6000
                        ? `${investCount?.amount} ₹`
                        : null
                    }
                    variant="info"
                    key={2}
                  />
                )}

                {/* Segment 3: 6000 to 10000 */}
                {investCount?.amount > 6000 && (
                  <ProgressBar
                    now={Math.min(investCount?.amount - 6000, 4000)}
                    max={10000}
                    label={
                      investCount?.amount <= 10000
                        ? `${investCount?.amount} ₹`
                        : null
                    }
                    variant="warning "
                    key={3}
                  />
                )}

                {/* Additional segment if amount exceeds 10000 */}
                {investCount?.amount > 10000 && (
                  <ProgressBar
                    now={investCount?.amount - 10000}
                    max={investCount?.amount} // Use dynamic max if amount exceeds 10000
                    label={`${investCount?.amount} ₹`}
                    variant="success"
                    key={4}
                  />
                )}
              </ProgressBar>
            </div>
            <br></br>
            <div className="d-flex mb-1">
              <div className="blue_round ms-2"></div>
              <div>Silver</div>
            </div>

            <div className="d-flex  mb-1">
              <div className="Yellow_round ms-2"></div>
              <div>Gold</div>
            </div>

            <div className="d-flex  mb-1">
              <div className="green_round ms-2"></div>
              <div>Platinum</div>
            </div>

            {/* <div className="d-flex  mb-1">
              <div>Above 10000</div>
              <div className="blue_round ms-2"></div>
            </div> */}
          </div>

          <div className="col-xxl-3 col-xl-3 col-lg-4">
            <div className="grand_total_table">
              <div className="orderss">
                <h6 className="ordersum">Order Summary</h6>
              </div>
              <Table responsive>
                <thead>
                  <tr className="top-border">
                    <th>Total amount</th>
                    <td>₹{totalPrice}</td>
                  </tr>
                </thead>
                {/* <thead>
                  <tr className="top-border">
                    <th>Discount</th>
                    <td>₹ {discount}</td>
                  </tr>
                </thead> */}

                <thead>
                  <tr className="top-border">
                    <th>Tax</th>
                    <td>₹{totalTax.toFixed(2)}</td>
                  </tr>
                </thead>

                {userdata?.membership &&
                  productmembership?.includes(userdata.membership) && (
                    <thead>
                      <tr className="top-border">
                        <th>
                          Extra Discount ({userdata.membership} Membership)
                        </th>
                        <td>
                          ₹
                          {(() => {
                            let discountRate =
                              userdata.membership === "Silver"
                                ? 0.15
                                : userdata.membership === "Gold"
                                ? 0.2
                                : userdata.membership === "Platinum"
                                ? 0.3
                                : 0;

                            const totalWithTax = totalPrice + totalTax;
                            discountAmount = totalWithTax * discountRate;

                            return discountAmount.toFixed(2);
                          })()}
                        </td>
                      </tr>
                    </thead>
                  )}

                {/* {location.pathname == "/product-cart-details" && (
                  <thead>
                    <tr>
                      <th>Shipping</th>
                      <td>₹00</td>
                    </tr>
                  </thead>
                )} */}

                {couponStatus && (
                  <thead>
                    <tr>
                      <th>Coupon Discount</th>
                      <td>₹{discount}</td>
                    </tr>
                  </thead>
                )}

                {type === "checkout" && (
                  <thead>
                    <tr className="Coupon_code_holder top-border">
                      <th className="Coupon_code">
                        <Form>
                          <Form.Control
                            type="text"
                            name="code"
                            placeholder="Enter Code"
                            value={copyCode}
                            {...register("code", {
                              required: "Code is required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.code,
                              "is-valid": getValues("code"),
                            })}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setCopyCode(inputValue);
                              setDiscount(0);
                              setCoupon("");
                              setCouponStatus(false);
                              setError("code", "");
                            }}
                          />
                          <span style={{ color: "red" }}>{cardError} </span>
                        </Form>
                      </th>

                      <td>
                        <div className="button-holder">
                          {couponStatus ? (
                            <Button
                              className="Checkout_btn"
                              type="button"
                              onClick={() => {
                                setDiscount(0);
                                setCoupon("");
                                setCouponStatus(false);
                                reset();
                              }}
                            >
                              Remove Coupon
                            </Button>
                          ) : (
                            <div>
                              <Button
                                className="Checkout_btn"
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                              >
                                Apply Coupon
                              </Button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr className="">
                      <td colSpan={2}>
                        <button
                          className="Checkout_btn show-coupon-btn-holder"
                          type="button"
                          onClick={handleOpenModal}
                        >
                          Show Coupons
                        </button>
                        <CoupanModal
                          setCopyCode={setCopyCode}
                          show={showModal}
                          handleClose={handleCloseModal}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <sup
                          className="text-danger"
                          style={{ fontSize: "1.2em" }}
                        >
                          {errors?.code?.message}
                        </sup>
                      </td>
                    </tr>
                  </thead>
                )}
                <CouponModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  message={`Coupon Applied Successfully`}
                />

                <thead>
                  <tr className="bottom-border">
                    <th className="bold-text">Total</th>
                    <td className="bold-text">
                      ₹
                      <span>
                        {(() => {
                          const subtotal =
                            Number(totalPrice) + Number(totalTax);
                          const discount = userdata?.membership
                            ? Number(
                                (
                                  (totalPrice + totalTax) *
                                  discountRate
                                ).toFixed(2)
                              )
                            : 0;

                          const totalAmount = Math.round(
                            subtotal - discountAmount.toFixed(2)
                          ).toFixed(2);
                          setAmount(totalAmount);

                          return totalAmount;
                        })()}
                      </span>
                    </td>
                  </tr>
                </thead>
              </Table>

              <div className="check-box-text-holder">
                <Form className="">
                  {location.pathname == "/product-cart-details" && (
                    <div className="check-box-holder-info">
                      <Form.Check
                        label="Quick Delivery"
                        name="Quick_Delivery"
                        type="checkbox"
                        className="me-1 my-2"
                        checked={quick_delivery}
                        onChange={handleQuickDeliveryChange}
                      />

                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-quick-delivery">
                            Quick Delivery
                          </Tooltip>
                        }
                        placement="left"
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="info-icon"
                        />
                      </OverlayTrigger>
                    </div>
                  )}

                  <div className="check-box-holder-info">
                    <Form.Check
                      type="checkbox"
                      label="Open Delivery"
                      checked={open_delivery}
                      onChange={handleOpenDeliveryChange}
                    />
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-open-delivery">
                          Open Delivery
                        </Tooltip>
                      }
                      placement="left"
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="info-icon"
                      />
                    </OverlayTrigger>
                  </div>
                </Form>
              </div>
            </div>

            {location.pathname == "/product-cart-details" && (
              <div className="instruction-field-holder mt-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    <b>Add Delivery Instruction</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            )}
            {/* <h3>{outOfStock}</h3> */}

            <div className="row justify-content-end mb-3">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6">
                <div className="button-holder text-end">
                  {/* {signin && */}
                  {showButton && (
                    <Link to={signin ? "/product-cart-details" : "/login"}>
                      <Button className="procheckout_btn" type="button">
                        Proceed to Checkout
                      </Button>
                    </Link>
                  )}
                  {/* } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <section className="Empty_Cart">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-5 col-md-6 col-sm-7 col-10">
                <div className="shop-now-content">
                  <div className="img-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/Cash_On_Delivery_Process/basket.png"
                      }
                      className="basket_img"
                    />
                  </div>
                  <div className="heading_holder mt-3">
                    <h5>Your cart is Empty</h5>
                    <p>
                      You have no items in your shopping cart. Let's go buy
                      something
                    </p>
                    <div className="main-btn-div">
                      <Link to={"/"}>
                        <button className="back-btn" type="button">
                          Shop Now
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* <CoupanModal
                show={showModal}
                handleClose={handleCloseModal}
            /> */}
    </>
  );
};

export default Cart;
