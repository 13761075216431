import React from "react";
import { Modal } from "react-bootstrap";

const Map_Modal_two = (props) => {
  return (
    <>
      <div className="">
        <Modal
          className="Newly_launch_modal Map_Modal"
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="videomokeup">
            <div className="allmodal">
              <Modal.Header closeButton></Modal.Header>

              <Modal.Body>
                <div className="logo-holder">
                  <img
                    className="img-logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Image/footer/footlogo.png"
                    }
                  />
                </div>
                <div
                  className="mapouter"
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    className="gmap_canvas"
                    style={{
                      overflow: "hidden",
                      background: "none!important",
                      width: "100%",
                      height: "416px",
                    }}
                  >
                    <iframe
                      className="gmap_iframe"
                      title="google-map"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      src="https://maps.google.com/maps?width=660&amp;height=416&amp;hl=en&amp;q=Building no. 50/L (Ground floor) River bank Colony, SP Banglow Road. Ghazipur, UP. 233001 IND.&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                      style={{
                        width: "100%",
                        height: "416px",
                      }}
                    ></iframe>
                    <a href="https://sprunkin.com/">Sprunki Incredibox</a>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Map_Modal_two;
