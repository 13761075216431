import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
  getDimension,
} from "./api";

import { Per_Page_Dropdown, Select2Data, RegxExpression } from "./common";
import { getCart } from "./apis/addToCart/addToCart";

import { getWishlist } from "./apis/Wishlist/Wishlist";

export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://192.168.14.132:3030";
  // const IMG_URL = "http://192.168.14.116:3030";
  // const IMG_URL = "http://127.0.0.1:3032";
  // const IMG_URL = "http://node.boomerbucks.in:3033";
  const IMG_URL = "https://boomerbucks-node.profcymabackend.com:3032";

  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  // const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [variant, setVariant] = useState("");
  const [subvariant, setsubVariant] = useState("");
  const [userdata, setUserData] = useState({});
  const [isAllow, setisAllow] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const [addCartModal, setAddCartModal] = useState(false);
  const [shimmerLoader, setShimmerLoader] = useState(false);

  const [open_delivery, setopen_delivery] = useState(false);
  const [quick_delivery, setquick_delivery] = useState("");
  const [appSetup, setAppSetup] = useState("");
  const [deliveryPincode, setDeliveryPincode] = useState("");
  const [sellerPincode, setSellerPincode] = useState("");
  const [outOfStock, setOutOfStock] = useState("");
  const minLength = 2;
  const maxLength = 30;

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const [cartCount, setCartCount] = useState(0);
  const [trigger, setTrigger] = useState(0);

  const triggerCartUpdate = () => {
    setTrigger(trigger === 0 ? 1 : 0);
  };

  useEffect(() => {
    const updateCartCount = async () => {
      if (signin) {
        try {
          const cartItems = await getData(`/cart/show`);
          setCartCount(cartItems?.data?.length || 0);
        } catch (error) {
          console.log(error);
        }
      } else if (Cookies.get("boomer_bucks_cart")) {
        setCartCount(JSON.parse(Cookies.get("boomer_bucks_cart")).length);
      }
    };

    updateCartCount();
  }, [Cookies.get("boomer_bucks_cart"), signin, trigger, addCartModal]);

  const isTokenValid = async () => {
    const token = Cookies.get("boomer_bucks_security");

    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("boomer_bucks_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        setisAllow([]);
        navigate("/");
      } else {
        setisAllow(decodedToken?.permissions);
        // getuserData(decodedToken.user);
        setUsertype(decodedToken.rolename);
        const cart = await getCart();
        await setCartCount(cart?.data?.length);
        const wishlist = await getWishlist();
        await setWishlistData(wishlist?.data);
        setSignin(true);
      }
    } else {
      setisAllow([]);
      setSignin(false);
      setUsertype("");
      // navigate("/");
    }
  };

  const wishlistContext = async () => {
    if (signin) {
      const res = await getWishlist();
      if (res?.success) {
        setWishlistData(res?.data);
      }
    }
  };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        ErrorNotify,
        variant,
        setVariant,
        subvariant,
        setsubVariant,
        // token,
        // setToken,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        Select2Data,
        Per_Page_Dropdown,
        RegxExpression,
        shimmerLoader,
        setShimmerLoader,
        addCartModal,
        setAddCartModal,
        cartCount,
        triggerCartUpdate,
        wishlistData,
        setWishlistData,
        wishlistContext,
        getDimension,
        open_delivery,
        setopen_delivery,
        quick_delivery,
        setquick_delivery,
        appSetup,
        setAppSetup,
        sellerPincode,
        setSellerPincode,
        deliveryPincode,
        setDeliveryPincode,
        outOfStock,
        setOutOfStock,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
